<template>
   <ObiText class="obi-cp" v-bind="attrs" v-on="$listeners" v-click-outside="closePopup">
      <ObiText class="obi-cp-input" @click="openPopup">
         <ObiText class="obi-cp-preview" :style="{ backgroundColor: inputValue }" />
         <ObiText class="obi-cp-label" v-html="inputValue" />
      </ObiText>
      <ObiText class="obi-cp-popup" :class="{ 'obi-cp-popup--visible': popupVisible }" ref="popup" />
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import ColorPickerUI from '@easylogic/colorpicker/src/colorpicker/';

export default {
   name: 'ObiColorPicker',

   props: {
      value: {
         default: '#222222',
      },
   },

   computed: {
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },

   data() {
      return {
         colorPicker: null,
         popupVisible: false,
      };
   },

   mounted() {
      this.colorPicker = ColorPickerUI.create({
         type: 'sketch',
         position: 'inline',
         mode: 'edit',
         container: this.$refs.popup.$el,
         onChange: (color) => {
            this.inputValue = color;
         },
      });
   },

   methods: {
      openPopup() {
         this.popupVisible = true;
      },
      closePopup() {
         this.popupVisible = false;
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-cp {
   position: relative;
   display: inline-grid;

   .obi-cp-input {
      padding: 6px;
      display: grid;
      cursor: pointer;
      grid-gap: 0.5rem;
      background: #fff;
      min-width: 100px;
      border-radius: 3px;
      align-items: center;
      border: 0.7px solid #e3e5e5;
      grid-template-columns: auto 1fr;

      .obi-cp-preview {
         width: 1rem;
         height: 1rem;
         border-radius: 3px;
      }

      .obi-cp-label {
         color: #777d96;
         font-size: 1rem;
         text-align: center;
      }
   }

   .obi-cp-popup {
      left: 110%;
      z-index: 999;
      display: none;
      position: absolute;
      transform: translateY(-25%);

      &.obi-cp-popup--visible {
         display: block;
      }
   }
}
</style>
